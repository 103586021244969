// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import styles from './Author.module.scss';

type Props = {
  author: {
    name: string,
    bio: string,
    photo: string
  },
  isIndex: ?boolean
};

function generateQuote() {

}

const Author = ({ author, isIndex }: Props) => (
  <div className={styles['author']}>
    <Link to="/">
    </Link>
    <p className={styles['author__subtitle']}>Transmissions from an<br/> Uncertain Future</p>
  </div>
);

export default Author;
