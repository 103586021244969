// @flow strict
import React from 'react';
import styles from './BlogLogo.module.scss';

export default function BlogLogo() {
  return (
      <div className={styles.glitchdiv}>
        <h1 className={styles.glitchlogo} data-text='0x174'>0x174</h1>
        <hr/>
      </div>
  );
}
